import React, { useState, useEffect } from "react";

import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useLocation
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createStandaloneToast } from "@chakra-ui/toast";

import "assets/css/font.css";
import "assets/css/App.css";
import theme from "theme/theme";
import { AppContext } from "contexts/AppContext";
import { authRoutes, routes } from "routes";
import { AlertContextProvider } from "components/alert-modal";
import {
    TGoogleData,
    useAuthService,
} from "services";

import CookieBanner from "components/cookie-banner/CookieBanner";
import raygun from "raygun";

// Global toast for services
const { ToastContainer } = createStandaloneToast({
    //theme,
    defaultOptions: {
        duration: 2000,
        isClosable: true,
        position: "top-right",
    }
});

function RootRoute() {
    const location = useLocation();

    useEffect(() => {
        raygun("trackEvent", { type: "pageView", path: location.pathname });
    }, [ location ]);

    return (
        <Outlet />
    );
}

function App() {
    const authService = useAuthService();

    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [ isInitialized, setIsInitialized ] = useState(false);
    const [ googleId, setGoogleId ] = useState<TGoogleData>({} as any);

    const router = createBrowserRouter([
        {
            element: <RootRoute />,
            children: isLoggedIn ? routes : authRoutes,
        }
    ]);

    const checkIfLoggedIn = async () => {
        let isAuthenticated = isLoggedIn;

        try {
            if (!isAuthenticated) {
                await authService.getToken();

                isAuthenticated = (await authService.isAuthenticated()).isAuthenticated;
            }

            if (!Object.keys(googleId).length) {
                await authService.getGoogleData().then((data) => setGoogleId(data));
            }

            setIsLoggedIn(isAuthenticated);
        } catch (error) {
            setIsLoggedIn(false);
        } finally {
            setIsInitialized(true);
        }
    };

    useEffect(() => {
        if (isInitialized)
            return;

        checkIfLoggedIn();
    }, []);


    const appContext = {
        googleId,
        isLoggedIn,
        setIsLoggedIn,
    };

    return (
        <ChakraProvider theme={theme}>
            <GoogleOAuthProvider clientId={googleId.clientId}>
                {isInitialized && <AppContext.Provider value={appContext}>
                    <AlertContextProvider>
                        <CookieBanner />
                        <RouterProvider router={router} />
                        <ToastContainer />
                    </AlertContextProvider>
                </AppContext.Provider>}
            </GoogleOAuthProvider>
        </ChakraProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as Element).render(<App />);
