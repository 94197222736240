import rg4js from "raygun4js";
import { onCLS, onFID, onFCP, onLCP, onTTFB, ReportCallback, Metric } from "web-vitals";

const apiKey = process.env.REACT_APP_RAYGUN_API_KEY;

const reportWebVitals = (onReport: ReportCallback) => {
    if (onReport && onReport instanceof Function) {
        onCLS(onReport);
        onFID(onReport);
        onFCP(onReport);
        onLCP(onReport);
        onTTFB(onReport);
    }
};

function initRaygun(): (key: string, value: any) => void {
    if (!apiKey)
        return (key: string, value: any) => {
            //console.warn(`Raygun stub, [${key}]=`, value);
        };

    rg4js("apiKey", apiKey);
    rg4js("enableCrashReporting", true);
    rg4js("enablePulse", true);

    reportWebVitals((metric: Metric) => {
        rg4js("trackEvent", {
            type: "customTiming",
            name: metric.name,
            duration: metric.value,
        });
    });

    return rg4js;
}

const raygun = initRaygun();

export default raygun;
